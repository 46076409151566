import React from 'react';
import { Route, Routes as ReactRoutes, Navigate } from "react-router-dom";
import Dashboard from './Dashboard/Dashboard';
import ProductList from './Products/ProductList';
import ProductEdit from './Products/ProductEdit';
import ProductAdd from './Products/ProductAdd';
import Header from '../components/common/Header';
import CouponsList from './Coupons/CouponsList';
import CouponsAdd from './Coupons/CouponsAdd';
import CouponsEdit from './Coupons/CouponsEdit';
import Invoices from './Accounts/Invoices';
import Expense from './Accounts/Expense';
import Salaryslip from './Accounts/Salaryslip';
import Chat from './App/Chat';
import Calendar from './App/Calendar';
import StaterPage from './Stater Page/StaterPage';
import SimpleInvoice from '../components/Accounts/Invoice/SimpleInvoice';
import TeamStaffList from './Users/UsersList';
import TeamStaffAdd from './Users/UsersAdd';
import TeamStaffEdit from './Users/UsersEdit';
import ServicesAdd from './Services/ServicesAdd';
import ServicesEdit from './Services/ServicesEdit';
import ServicesList from './Services/ServicesList';
import AddFAQ from './FAQ/AddFAQ';
import EditFAQ from './FAQ/EditFAQ';
import FAQList from './FAQ/FAQList';
import ExpertiseAdd from './Expertise/ExpertiseAdd';
import ExpertiseEdit from './Expertise/ExpertiseEdit';
import ExpertiseList from './Expertise/ExpertiseList';
import HeatMapBox from './HeatMap/HeatMapBox';
import { PrivacyPolicy } from './PrivacyPolicy/PrivacyPolicy';
import AstrologerList from './Astrologer/AstrologerList';
import AstrologerEdit from './Astrologer/AstrologerEdit';
import AstrologerAdd from './Astrologer/AstrologerAdd';
import BookingAdd from './Booking/BookingAdd';
import BookingEdit from './Booking/BookingEdit';
import BookingList from './Booking/BookingList';
import LanguageEdit from './Language/LanguageEdit';
import LanguageAdd from './Language/LanguageAdd';
import LanguageList from './Language/LanguageList';
import SkillsList from './Skills/SkillsList';
import SkillsAdd from './Skills/SkillsAdd';
import SkillsEdit from './Skills/SkillsEdit';
import CourseAdd from './Course/CourseAdd';
import CourseList from './Course/CourseList';
import CategoryAdd from './Category/CategoryAdd';
import CategoryEdit from './Category/CategoryEdit';
import CategoryList from './Category/CategoryList';
import StudentList from './Student/StudentList';
import LeadsList from './Leads/LeadsList';
import StudentView from './Student/StudentView';

function MainIndex(props) {
  const { activekey } = props;
  const userToken = localStorage.getItem("vedhvidhi-admin-token");

  if (!userToken) {
    return <Navigate to={process.env.PUBLIC_URL + "/sign-in"} />;
  }

  return (
    <div className='main px-lg-4 px-md-4' >
      {activekey === "/chat" ? "" : <Header />}
      <div className="body d-flex py-3 ">
        <ReactRoutes>
          <Route exact path={process.env.PUBLIC_URL + "/"} element={<Dashboard />} />
          <Route exact path={process.env.PUBLIC_URL + "/dashboard"} element={<Dashboard />} />

          <Route exact path={process.env.PUBLIC_URL + '/product-list'} element={<ProductList />} />
          <Route exact path={process.env.PUBLIC_URL + '/product-edit/:id'} element={<ProductEdit />} />
          <Route exact path={process.env.PUBLIC_URL + '/product-Add'} element={<ProductAdd />} />

          <Route exact path={process.env.PUBLIC_URL + '/coupons-list'} element={<CouponsList />} />
          <Route exact path={process.env.PUBLIC_URL + '/coupons-add'} element={<CouponsAdd />} />
          <Route exact path={process.env.PUBLIC_URL + '/coupons-edit/:id'} element={<CouponsEdit />} />

          <Route exact path={process.env.PUBLIC_URL + '/invoices'} element={<Invoices />} />
          <Route exact path={process.env.PUBLIC_URL + '/simple-invoice'} element={<SimpleInvoice />} />

          <Route exact path={process.env.PUBLIC_URL + '/expense'} element={<Expense />} />
          <Route exact path={process.env.PUBLIC_URL + '/salaryslip'} element={<Salaryslip />} />
          <Route exact path={process.env.PUBLIC_URL + '/chat'} element={<Chat />} />
          <Route exact path={process.env.PUBLIC_URL + '/calendar'} element={<Calendar />} />

          <Route exact path={process.env.PUBLIC_URL + '/stater-page'} element={<StaterPage />} />

          <Route exact path={process.env.PUBLIC_URL + '/users-list'} element={<TeamStaffList />} />
          <Route exact path={process.env.PUBLIC_URL + '/users-add'} element={<TeamStaffAdd />} />
          <Route exact path={process.env.PUBLIC_URL + '/users-edit/:id'} element={<TeamStaffEdit />} />

          <Route exact path={process.env.PUBLIC_URL + '/expertise-list'} element={<ExpertiseList />} />
          <Route exact path={process.env.PUBLIC_URL + '/expertise-add'} element={<ExpertiseAdd />} />
          <Route exact path={process.env.PUBLIC_URL + '/expertise-edit/:id'} element={<ExpertiseEdit />} />

          <Route exact path={process.env.PUBLIC_URL + '/skills-list'} element={<SkillsList />} />
          <Route exact path={process.env.PUBLIC_URL + '/skills-add'} element={<SkillsAdd />} />
          <Route exact path={process.env.PUBLIC_URL + '/skills-edit/:id'} element={<SkillsEdit />} />

          <Route exact path={process.env.PUBLIC_URL + '/service-list'} element={<ServicesList />} />
          <Route exact path={process.env.PUBLIC_URL + '/service-add'} element={<ServicesAdd />} />
          <Route exact path={process.env.PUBLIC_URL + '/service-edit/:id'} element={<ServicesEdit />} />

          <Route exact path={process.env.PUBLIC_URL + '/faq'} element={<FAQList />} />
          <Route exact path={process.env.PUBLIC_URL + '/faq-add'} element={<AddFAQ />} />
          <Route exact path={process.env.PUBLIC_URL + '/faq-edit/:id'} element={<EditFAQ />} />

          <Route exact path={process.env.PUBLIC_URL + '/astrologer-list'} element={<AstrologerList />} />
          <Route exact path={process.env.PUBLIC_URL + '/astrologer-edit'} element={<AstrologerEdit />} />
          <Route exact path={process.env.PUBLIC_URL + '/astrologer-add'} element={<AstrologerAdd />} />

          <Route exact path={process.env.PUBLIC_URL + '/booking-list'} element={<BookingList />} />
          <Route exact path={process.env.PUBLIC_URL + '/booking-edit'} element={<BookingEdit />} />
          <Route exact path={process.env.PUBLIC_URL + '/booking-add'} element={<BookingAdd />} />

          <Route exact path={process.env.PUBLIC_URL + '/language-list'} element={<LanguageList />} />
          <Route exact path={process.env.PUBLIC_URL + '/language-edit'} element={<LanguageEdit />} />
          <Route exact path={process.env.PUBLIC_URL + '/language-add'} element={<LanguageAdd />} />

          <Route exact path={process.env.PUBLIC_URL + '/course-management'} element={<CourseList />} />
          <Route exact path={process.env.PUBLIC_URL + '/course-edit/:id'} element={<CourseAdd />} />
          <Route exact path={process.env.PUBLIC_URL + '/course-add'} element={<CourseAdd />} />

          <Route exact path={process.env.PUBLIC_URL + '/category-management'} element={<CategoryList />} />
          <Route exact path={process.env.PUBLIC_URL + '/category-edit/:id'} element={<CategoryEdit />} />
          <Route exact path={process.env.PUBLIC_URL + '/category-add'} element={<CategoryAdd />} />

          <Route exact path={process.env.PUBLIC_URL + '/heat-map'} element={<HeatMapBox />} />

          <Route exact path={process.env.PUBLIC_URL + '/student-management'} element={<StudentList />} />
          <Route exact path={process.env.PUBLIC_URL + '/student-view/:id'} element={<StudentView />} />

          <Route exact path={process.env.PUBLIC_URL + '/leads-management'} element={<LeadsList />} />

          <Route exact path={process.env.PUBLIC_URL + '/privacy-policy'} element={<PrivacyPolicy />} />
          <Route exact path={process.env.PUBLIC_URL + '/terms-conditions'} element={<PrivacyPolicy />} />

        </ReactRoutes>
      </div>
    </div>
  );
}
export default MainIndex;