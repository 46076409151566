import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategory } from '../../Redux/Reducers/CategoryReducer';
import axios from 'axios';
import { uploadImagecertifates } from '../../Redux/Actions/Action';
import { useNavigate, useParams } from 'react-router';
import { fetchCourse } from '../../Redux/Reducers/CourseReducer';

function CourseAdd(props) {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const course = useSelector((state) => state?.CourseReducer?.course);

    useEffect(() => {
        if (id) {
            dispatch(fetchCourse(id));
        }
    }, [dispatch, id])

    const [sections, setSections] = useState({
        batchDetails: [{ batchName: "", startTime: "", endTime: "", startDate: "", endDate: "" }],
        learningOutcomes: [""],
        courseContents: [{ cTitle: "", cDescription: "" }],
        programBenefits: [""],
        title: "",
        description: "",
        price: "",
        courseImg: "",
        about: "",
        courseType: "",
        courseVid: "",
        categoryId: "",
        tutorsImage: "",
        tutorsName: "",
        tutorsDesc: "",
        certificateImg: "",
        progFile: "",
        brochureFile: "",
        steps: props.steps,
        status: props.steps === 8 ? true : false
    });

    useEffect(() => {
        if (course?.data) {
            setSections(prevSections => ({
                ...prevSections,
                batchDetails: course.data.batchDetails?.map(item => ({
                    batchName: item.batchName,
                    startTime: item.startTime,
                    endTime: item.endTime,
                    startDate: item.startDate,
                    endDate: item.endDate,
                })) || prevSections.batchDetails,
                learningOutcomes: course.data.learningOutcomes || prevSections.learningOutcomes,
                courseContents: course.data.courseContents?.map(item => ({
                    cTitle: item.cTitle,
                    cDescription: item.cDescription
                })) || prevSections.courseContents,
                programBenefits: course.data.programBenefits || prevSections.programBenefits,
                title: course.data.title || prevSections.title,
                courseType: course.data.courseType || prevSections.courseType,
                description: course.data.description || prevSections.description,
                about: course.data.about || prevSections.about,
                categoryId: course.data.categoryId || prevSections.categoryId,
                tutorsName: course.data.tutorsName || prevSections.tutorsName,
                tutorsDesc: course.data.tutorsDesc || prevSections.tutorsDesc,
                courseVid: course.data?.courseVid || prevSections.courseVid,
                price: course.data?.price || prevSections.price,
                courseImg: course.data?.courseImg || prevSections.courseImg,
                tutorsImage: course?.data?.tutorsImage || prevSections.tutorsImage,
                certificateImg: course.data?.certificateImg || prevSections.certificateImg,
                progFile: course.data?.progFile || prevSections.progFile,
                brochureFile: course.data?.brochureFile || prevSections.brochureFile,
                status: props.steps === 8 ? true : false
            }));
        }
    }, [course]);

    const handleImageChange = async (e, fieldName) => {
        let file = e.target.files[0];
        if (file) {
            try {
                let imageUrl = await dispatch(uploadImagecertifates(file));
                if (imageUrl) {
                    setSections(prevData => {
                        let newData = { ...prevData, [fieldName]: imageUrl };
                        return newData;
                    });
                }
            } catch (error) {
                console.error("Image upload failed:", error);
            }
        }
    };

    // Update sections.steps whenever props.steps changes
    useEffect(() => {
        setSections((prevSections) => ({
            ...prevSections,
            steps: props.steps + 1
        }));
    }, [props.steps]);

    const category = useSelector((state) => state.CategoryReducer.category);
    useEffect(() => {
        dispatch(fetchCategory());
    }, [dispatch])

    const handleAddSection = (sectionName, initialState) => {
        setSections((prev) => ({
            ...prev,
            [sectionName]: [...prev[sectionName], initialState],
        }));
    };

    const handleRemoveSection = (sectionName, index) => {
        setSections((prev) => ({
            ...prev,
            [sectionName]: prev[sectionName].filter((_, i) => i !== index),
        }));
    };

    const onNext = (e) => {
        if (props.steps < 7) {
            props.handleSteps(props.steps + 1);
        }
    };

    const onBack = (e) => {
        e.preventDefault();
        if (props.steps > 0) {
            props.handleSteps(props.steps - 1);
        }
    };

    const myColors = [
        "purple",
        "#785412",
        "#452632",
        "#856325",
        "#963254",
        "#254563",
        "white"
    ];

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ align: ["right", "center", "justify"] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
            [{ color: myColors }],
            [{ background: myColors }]
        ]
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "link",
        "color",
        "image",
        "background",
        "align"
    ];

    const handleCategoryChange = (event) => {
        const selectedCategoryId = event.target.value;
        setSections((prevState) => ({
            ...prevState,
            categoryId: selectedCategoryId,
        }));
    };

    const onSubmit = async (data) => {
        try {
            const token = localStorage.getItem("vedhvidhi-admin-token");
            const url = `${process.env.REACT_APP_BASEURL}/admin/course/`;
            const config = { headers: { Authorization: token } };
            let response;

            if (id) {
                response = await axios.patch(`${url}${id}`, sections, config);
            } else {
                response = await axios.post(url, sections, config);
            }
            if (response.status) {
                toast.success(response.data.message);
                navigate("/course-management");
            }
        } catch (error) {
            console.error("Error adding category:", error);
            toast.error(error.response?.data?.message);
        }
    };

    return (
        <>
            <div className="card-body cardBody_box">

                {props?.steps === 0 && <form className="row g-4">
                    <div className="col-md-12">
                        <h5 className='fw-bold mb-0'>Course Details Section</h5>
                    </div>

                    <div className="col-md-4">
                        <label className="form-label">Title of the Course</label>
                        <span style={{ color: "red" }}>*</span>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Enter course title"
                            value={sections.title}
                            onChange={(e) => {
                                setSections((prev) => ({
                                    ...prev,
                                    title: e.target.value,
                                }));
                            }}
                        />
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="form-label">Category</label> <span className="text-danger">*</span>
                            <select className="form-control" value={sections.categoryId} onChange={handleCategoryChange}>
                                <option value="">Select a category</option>
                                {category?.data?.map((cat) => (
                                    <option key={cat?._id} value={cat?._id}>
                                        {cat?.categoryName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="form-label">Course Type</label> <span className="text-danger">*</span>
                            <select className="form-control" value={sections.courseType}
                                onChange={(e) => {
                                    setSections((prev) => ({
                                        ...prev,
                                        courseType: e.target.value,
                                    }));
                                }}>
                                <option value="">Select a type</option>
                                <option value="live">Live Course</option>
                                <option value="recorded">Pre-Recorded Course</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="form-label">Course Overview Video</label> <span className="text-danger">*</span>
                            <input
                                className="form-control"
                                type="file"
                                placeholder='Enter coupon name'
                                onChange={(e) => handleImageChange(e, "courseVid")}
                            />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="form-label">Course Overview Image</label> <span className="text-danger">*</span>
                            <input
                                className="form-control"
                                type="file"
                                placeholder='Enter coupon name'
                                onChange={(e) => handleImageChange(e, "courseImg")}
                            />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <label className="form-label">Price</label>
                        <span style={{ color: "red" }}>*</span>
                        <input
                            className="form-control"
                            type="number"
                            placeholder="Enter course price"
                            value={sections.price}
                            onChange={(e) => {
                                setSections((prev) => ({
                                    ...prev,
                                    price: e.target.value,
                                }));
                            }}
                        />
                    </div>

                    <div className="col-md-12">
                        <label className="form-label">About Course</label>
                        <span style={{ color: "red" }}>*</span>
                        <ReactQuill
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            value={sections.about}
                            onChange={(value) => {
                                setSections((prev) => ({
                                    ...prev,
                                    about: value,
                                }));
                            }}
                        />
                    </div>


                    <div className="col-md-12">
                        <label className="form-label">Description of the Course</label>
                        <span style={{ color: "red" }}>*</span>
                        <ReactQuill
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            value={sections.description}
                            onChange={(value) => {
                                setSections((prev) => ({
                                    ...prev,
                                    description: value,
                                }));
                            }}
                        />
                    </div>

                </form>}

                {props?.steps === 1 &&
                    <form className="row g-4">
                        <div className="col-md-12">
                            <h5 className="fw-bold mb-0">Batch Details Section</h5>
                        </div>
                        {sections.batchDetails.map((batch, index) => (
                            <div className="d-flex align-items-center gap-4">
                                <div className="row g-4">

                                    <div className="col-md-4">
                                        <label className="form-label">Batch Name</label>
                                        <span className="text-danger">*</span>
                                        <input className="form-control" type="text" placeholder="Enter Batch Name"
                                            value={batch.batchName}
                                            onChange={(e) => {
                                                const updated = [...sections.batchDetails];
                                                updated[index] = {
                                                    ...updated[index],
                                                    batchName: e.target.value,
                                                };
                                                setSections((prev) => ({
                                                    ...prev,
                                                    batchDetails: updated,
                                                }));
                                            }} />
                                    </div>

                                    <div className="col-md-4">
                                        <label className="form-label">Start Timings</label>
                                        <span style={{ color: "red" }}>*</span>
                                        <input className="form-control" type="time" value={batch.startTime}
                                            onChange={(e) => {
                                                const updated = [...sections.batchDetails];
                                                updated[index] = {
                                                    ...updated[index],
                                                    startTime: e.target.value,
                                                };
                                                setSections((prev) => ({
                                                    ...prev,
                                                    batchDetails: updated,
                                                }));
                                            }} />
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label">End Timings</label>
                                        <span style={{ color: "red" }}>*</span>
                                        <input className="form-control" type="time" value={batch.endTime}
                                            onChange={(e) => {
                                                const updated = [...sections.batchDetails];
                                                updated[index] = {
                                                    ...updated[index],
                                                    endTime: e.target.value,
                                                };
                                                setSections((prev) => ({
                                                    ...prev,
                                                    batchDetails: updated,
                                                }));
                                            }} />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Start Date</label>
                                        <span style={{ color: "red" }}>*</span>
                                        <input className="form-control" type="date" value={batch.startDate}
                                            onChange={(e) => {
                                                const updated = [...sections.batchDetails];
                                                updated[index] = {
                                                    ...updated[index],
                                                    startDate: e.target.value,
                                                };
                                                setSections((prev) => ({
                                                    ...prev,
                                                    batchDetails: updated,
                                                }));
                                            }} />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">End Date</label>
                                        <span style={{ color: "red" }}>*</span>
                                        <input className="form-control" type="date" value={batch.endDate}
                                            onChange={(e) => {
                                                const updated = [...sections.batchDetails];
                                                updated[index] = {
                                                    ...updated[index],
                                                    endDate: e.target.value,
                                                };
                                                setSections((prev) => ({
                                                    ...prev,
                                                    batchDetails: updated,
                                                }));
                                            }} />
                                    </div>
                                </div>
                                <div>
                                    {index === 0 ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary text-uppercase px-3"
                                            onClick={() => handleAddSection("batchDetails", {})}
                                        >
                                            +
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-danger text-uppercase px-3"
                                            onClick={() => handleRemoveSection("batchDetails", index)}
                                        >
                                            -
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </form>
                }

                {props?.steps === 2 && <form className="row g-4">
                    <div className="col-md-12">
                        <h5 className='fw-bold mb-0'>Tutor Details Section (To confirm if each batch might have different tutor)</h5>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label">Tutor's Image</label> <span className="text-danger">*</span>
                            <input className="form-control" type="file" placeholder='Upload Image' onChange={(e) => handleImageChange(e, "tutorsImage")} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label">Tutor's name</label> <span className="text-danger">*</span>
                            <input className="form-control" type="text" placeholder='Tutors name'
                                value={sections.tutorsName}
                                onChange={(e) => {
                                    setSections((prev) => ({
                                        ...prev,
                                        tutorsName: e.target.value,
                                    }));
                                }} />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-label">Tutor Description</label> <span className="text-danger">*</span>
                            <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                value={sections.tutorsDesc}
                                onChange={(value) => {
                                    setSections((prev) => ({
                                        ...prev,
                                        tutorsDesc: value,
                                    }));
                                }}
                            />
                        </div>
                    </div>
                </form>}

                {props?.steps === 3 && <form className="row g-4">
                    <div className="col-md-12">
                        <h5 className='fw-bold mb-0'>Certificate Details Section</h5>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-label">Certificate Overview Image</label> <span className="text-danger">*</span>
                            <input className="form-control" type="file" placeholder='Upload Image' onChange={(e) => handleImageChange(e, "certificateImg")} />
                        </div>
                    </div>
                </form>}

                {props?.steps === 4 &&
                    <form className="row g-4">
                        <div className="col-md-12">
                            <h5 className="fw-bold mb-0">Learning Outcomes Section</h5>
                        </div>
                        {sections.learningOutcomes.map((outcome, index) => (
                            <div className="d-flex align-items-end gap-4">
                                <div className="row g-4 w-100">
                                    <div className="col-md-12">
                                        <label className="form-label">What Users Will Learn</label>
                                        <span className="text-danger">*</span>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="What Users Will Learn"
                                            value={outcome}
                                            onChange={(e) => {
                                                const updated = [...sections.learningOutcomes];
                                                updated[index] = e.target.value;
                                                setSections((prev) => ({
                                                    ...prev,
                                                    learningOutcomes: updated,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    {index === 0 ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary text-uppercase px-3"
                                            onClick={() => handleAddSection("learningOutcomes", "")}
                                        >
                                            +
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-danger text-uppercase px-3"
                                            onClick={() => handleRemoveSection("learningOutcomes", index)}
                                        >
                                            -
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </form>
                }

                {props?.steps === 5 && <form className="row g-4">
                    <div className="col-md-12">
                        <h5 className='fw-bold mb-0'>Course Content Section</h5>
                    </div>

                    {sections.courseContents.map((course, index) => (
                        <div className='d-flex align-items-center gap-4'>
                            <div className="row g-4 w-100">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label">Chapter Title</label> <span className="text-danger">*</span>
                                        <input className="form-control" type="text" placeholder='Chapter Title' value={course.cTitle}
                                            onChange={(e) => {
                                                const updated = [...sections.courseContents];
                                                updated[index] = {
                                                    ...updated[index],
                                                    cTitle: e.target.value,
                                                };
                                                setSections((prev) => ({
                                                    ...prev,
                                                    courseContents: updated,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label">Chapter Description</label> <span className="text-danger">*</span>
                                        <textarea value={course.cDescription} style={{ minHeight: '100px' }} className="form-control" placeholder="Enter text here..."
                                            onChange={(e) => {
                                                const updated = [...sections.courseContents];
                                                updated[index] = {
                                                    ...updated[index],
                                                    cDescription: e.target.value,
                                                };
                                                setSections((prev) => ({
                                                    ...prev,
                                                    courseContents: updated,
                                                }));
                                            }} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                {index === 0 ? (
                                    <button
                                        type="button"
                                        className="btn btn-primary text-uppercase px-3"
                                        onClick={() => handleAddSection("courseContents", "")}
                                    >
                                        +
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn btn-danger text-uppercase px-3"
                                        onClick={() => handleRemoveSection("courseContents", index)}
                                    >
                                        -
                                    </button>
                                )}
                            </div>
                        </div>))}
                </form>}

                {props?.steps === 6 && <form className="row g-4">
                    <div className="col-md-12">
                        <h5 className='fw-bold mb-0'>Program Files Section</h5>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label">Program Syllabus File</label> <span className="text-danger">*</span>
                            <input className="form-control" type="file" onChange={(e) => handleImageChange(e, "progFile")} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label">Brochure File</label> <span className="text-danger">*</span>
                            <input className="form-control" type="file" onChange={(e) => handleImageChange(e, "brochureFile")} />
                        </div>
                    </div>
                </form>}

                {props?.steps === 7 && <form className="row g-4">
                    <div className="col-md-12">
                        <h5 className='fw-bold mb-0'>Program Benefits Section</h5>
                    </div>

                    {sections.programBenefits?.map((benefit, index) => (
                        <div className="col-md-12">
                            <div className='d-flex align-items-end gap-4 '>
                                <div className="form-group w-100">
                                    <label className="form-label">Benefit Point</label> <span className="text-danger">*</span>
                                    <input className="form-control" type="text" placeholder='Benefit Point' value={benefit}
                                        onChange={(e) => {
                                            const updated = [...sections.programBenefits];
                                            updated[index] = e.target.value;
                                            setSections((prev) => ({
                                                ...prev,
                                                programBenefits: updated,
                                            }));
                                        }}
                                    />
                                </div>
                                <div>
                                    {index === 0 ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary text-uppercase px-3"
                                            onClick={() => handleAddSection("programBenefits", "")}
                                        >
                                            +
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-danger text-uppercase px-3"
                                            onClick={() => handleRemoveSection("programBenefits", index)}
                                        >
                                            -
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>))}

                </form>}

                <div className="col-12 mt-4">
                    <div className='d-flex align-items-center justify-content-between'>
                        <button onClick={onBack} className="btn btn-secondary text-uppercase px-3">{props?.steps === 0 ? 'Cancel' : 'Back'}</button>
                        <div className='d-flex align-items-center gap-2'>
                            {/* {props?.steps < 7 && <button type="submit" className="btn btn-primary text-uppercase px-3" onClick={onSubmit}>
                                Save as Draft
                            </button>} */}
                            <button onClick={() => {
                                if (props.steps === 7) {
                                    onSubmit();
                                } else {
                                    onNext();
                                }
                            }} type="submit" className="btn btn-primary text-uppercase px-3">
                                {props.steps === 7 ? 'Save' : 'Next'}
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default CourseAdd;