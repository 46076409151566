import React, { useState } from 'react';
import PageHeader1 from '../../components/common/PageHeader1';
import Course from '../../components/Course/CourseAddEdit';
import TrackStepper from '../../components/Stepper/TrackStepper';
import { useLocation } from 'react-router';

function CourseAdd() {
    const [steps, setSteps] = useState(0);
    const location = useLocation();

    const handleSteps = (steps) => {
        setSteps(steps)
    }

    return (
        <div className="body d-flex py-lg-3 py-md-2">
            <div className="container-xxl">
                <PageHeader1 pagetitle={location.pathname?.split('/')?.[1] === 'course-edit' ? 'Edit Course' : 'Add Course'} />
                <div className="row clearfix g-3">
                    <div className="col-lg-12">
                        <TrackStepper steps={steps} />
                    </div>
                    <div className="col-lg-12">
                        <Course
                            handleSteps={handleSteps}
                            steps={steps}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CourseAdd;