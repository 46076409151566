import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStudent } from '../../Redux/Reducers/StudentReducer';

function StudentView() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.StudentReducer.students);

    useEffect(() => {
        dispatch(fetchStudent(id));
    }, [dispatch, id]);

    return (
        <>
            <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold">User information</h6>
            </div>
            <div className="card-body">
                <form className="row g-4">

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">Full Name</label>
                            <input
                                className="form-control"
                                value={user?.data?.userId?.fullName || 'NA'}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">Email</label>
                            <input
                                className="form-control"
                                disabled
                                value={user?.data?.userId?.email || 'NA'}

                            />
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">Mobile number</label>
                            <input
                                className="form-control"
                                value={user?.data?.userId?.mobileNumber || 'NA'}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">Date of birth</label>
                            <input
                                className="form-control"
                                value={user?.data?.userId?.dob || 'NA'}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="form-group">
                            <label className="form-label">Address</label>
                            <input
                                className="form-control"
                                value={
                                    [
                                        user?.data?.userId?.houseNo,
                                        user?.data?.userId?.street,
                                        user?.data?.userId?.landmark,
                                        user?.data?.userId?.state,
                                        user?.data?.userId?.city,
                                        user?.data?.userId?.pincode
                                    ]
                                        .filter(Boolean)
                                        .join(', ') || 'NA'
                                }
                                disabled
                            />
                        </div>
                    </div>

                </form>
            </div>

            <hr />

            {user?.data?.courseId?.batchDetails?.map((item, index) => (
                <div>
                    <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                        <h6 className="mb-0 fw-bold">{`Batch ${index + 1}`}</h6>
                    </div>
                    <div className="card-body">
                        <form className="row g-4">
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label className="form-label">Course Image</label>
                                    <br />
                                    <img src={user?.data?.courseId?.courseImg || 'NA'} style={{ width: "100%" }} />
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label className="form-label">Course Title</label>
                                    <input
                                        className="form-control"
                                        disabled
                                        value={user?.data?.courseId?.title || 'NA'}

                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label mt-2">Batch Name</label>
                                    <input
                                        className="form-control"
                                        value={item?.batchName || 'NA'}
                                        disabled
                                    />
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label className="form-label">Start Date</label>
                                    <input
                                        className="form-control"
                                        value={item?.startDate || 'NA'}
                                        disabled
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label mt-2">Purchased Date</label>
                                    <input
                                        className="form-control"
                                        value={user?.data?.createdAt?.split("T")?.[0] || 'NA'}
                                        disabled
                                    />
                                </div>
                            </div>

                            <div className="col-sm-4">

                            </div>

                        </form>
                    </div>
                </div>
            ))}
        </>
    );
}

export default StudentView;